@import './colors';
@import './responsive.scss';
@import url('https://fonts.googleapis.com/css?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap');

@keyframes blink {
  0% {
    display: none;
  }
  100% {
    display: block;
  }
}

* {
  font-family: 'Share Tech Mono', monospace !important;
  font-weight: 100;
  font-size: 16px;
  line-height: 1.5;
}
body {
  background-color: $background-color;
  padding: 25px;
  margin: 0;
  color: white;
  cursor: url(https://nienormalny.org/assets/pixel.cur), auto;

  .socials {
    position: fixed;
    z-index: 999;
    top: 15px;
    right: 15px;
    display: flex;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 10px;
      min-width: inherit;
      margin: 0 5px;
      background-color: black;
    }
  }
  h1 {
    letter-spacing: 1px;
    text-align: center;
    font-size: 2.5em;
    & > * {
      font-size: 1em;
    }
  }
  h2,
  h3 {
    margin: 0;
    letter-spacing: 1px;
    text-align: center;
  }
  .flex-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .dotted-border {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    & > span {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      z-index: 1;
    }
    &.orange {
      .top {
        background-image: linear-gradient(to right,$orange 50%, transparent 50%);
      }
      .right {
        background-image: linear-gradient($orange 50%, transparent 50%);
      }
      .bottom {
        background-image: linear-gradient(to right,$orange 50%, transparent 50%);
      }
      .left {
        background-image: linear-gradient($orange 50%, transparent 50%);
      }
    }
    &.blue {
      .top {
        background-image: linear-gradient(to right,$blue 50%, transparent 50%);
      }
      .right {
        background-image: linear-gradient($blue 50%, transparent 50%);
      }
      .bottom {
        background-image: linear-gradient(to right,$blue 50%, transparent 50%);
      }
      .left {
        background-image: linear-gradient($blue 50%, transparent 50%);
      }
    }
    .top {
      background-image: linear-gradient(to right,white 50%, transparent 50%);
      background-position: top;
      background-size: 8px 2px;
      background-repeat: repeat-x;
    }
    .right {
      background-image: linear-gradient(white 50%, transparent 50%);
      background-position: right;
      background-size: 2px 8px;
      background-repeat: repeat-y;
    }
    .bottom {
      background-image: linear-gradient(to right,white 50%, transparent 50%);
      background-position: bottom;
      background-size: 8px 2px;
      background-repeat: repeat-x;
    }
    .left {
      background-image: linear-gradient(white 50%, transparent 50%);
      background-position: left;
      background-size: 2px 8px;
      background-repeat: repeat-y;
    }
  }
  .repo-box,
  .pvp-box {
    position: relative;
    width: 200px;
    margin: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h2 {
      text-align: center;
    }
    &:not(.repo-box) {
      width: 350px;
      h2 {
        font-size: 20px;
        margin: 10px 0;
      }
      .techs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0 0 15px 0;
        list-style: none;
        li {
          position: relative;
          margin: 5px;
          padding: 5px 10px;
          text-transform: uppercase;
          color: $blue;
          font-size: 12px;
        }
      }
    }
    .description {
      font-size: 14px;
      color: $orange;
      padding: 10px;
      text-align: center;
      position: relative;
      margin-bottom: 15px;
    }
    &.not-active {
      & > * {
        filter: grayscale(1) blur(1px);
        opacity: .5;
      }
      &:after {
        content: "Not Active";
        color: red;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        font-size: 2rem;
        z-index: 2;
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 25px;
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      input {
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }
}
:not(section#mini-editor) {
  ul {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  .start-date {
    font-size: 14px;
  }
}
}

.btn {
  text-decoration: none;
  cursor: pointer;
  color: white;
  padding: 8px 10px;
  margin: 5px 0;
  word-break: keep-all;
  white-space: nowrap;
  text-align: center;
  min-width: 150px;
  font-size: 14px;
  position: relative;
  &:hover {
    color: $green;
    .top {
      background-image: linear-gradient(to right,$green 50%, transparent 50%);
    }
    .right {
      background-image: linear-gradient($green 50%, transparent 50%);
    }
    .bottom {
      background-image: linear-gradient(to right,$green 50%, transparent 50%);
    }
    .left {
      background-image: linear-gradient($green 50%, transparent 50%);
    }
  }
  &.btn-secondary {
    &:hover {
      color: $btn-secondary-color;
      .top {
        background-image: linear-gradient(to right,$btn-secondary-color 50%, transparent 50%);
      }
      .right {
        background-image: linear-gradient($btn-secondary-color 50%, transparent 50%);
      }
      .bottom {
        background-image: linear-gradient(to right,$btn-secondary-color 50%, transparent 50%);
      }
      .left {
        background-image: linear-gradient($btn-secondary-color 50%, transparent 50%);
      }
    }
  }
}
.m-top-auto {
  margin-top: auto;
}
#hello-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 50px);
}
.standard-input {
  background-color: transparent;
  color: white;
  border: 0;
  padding: 5px 10px;
  position: relative;
  z-index: 1;
  outline: none;
  &.disabled {
    color: $silver;
  }
}
.not-found {
  margin: 0 0 10px 0;
  font-size: 12px;
  color: $silver;
  span {
    color: $red;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    // display: inline-block;
    vertical-align: bottom;
    font-size: 12px;
  }
}
.command-box {
  width: 640px;
  height: 380px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}
.pages {
  li {
    display: inline-block;
    margin-right: 15px;
    color: $secondary-color;
    &:before {
      content: '|';
      margin-right: 15px;
    }
    &:last-child:after {
      content: '|';
      margin-left: 15px;
    }
  }
}
#navigation {
  width: 640px;
  height: 380px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  background-color: $black-transparent;
  padding: 5px 0 10px 10px;
  transition: all 0.3s ease;
  .input-box {
    left: 15px;
    color: $green;
    margin: 0;
    overflow-x: hidden;
    .help-info {
      color: $secondary-color;
    }
  }
  &.closed {
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    z-index: 2;
    &:after {
      content: '>';
      color: $green;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    .command-box {
      width: 30px;
      height: 30px;
    }
    transform: translate(0, 0);
    .input-box {
      display: none;
    }
  }
  &:not(.closed) + .help {
    left: 15px;
  }
  .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 0;
    top: -45px;
    right: 0;
    background-color: $black-transparent;
    & > span {
      display: block;
      height: 2px;
      width: 20px;
      position: absolute;
      background-color: $green;
      top: 14px;
      left: 5px;
      &:first-of-type {
        transform: rotate(-45deg);
      }
      &:last-child {
        transform: rotate(45deg);
      }
    }
  }
}
.help {
  top: 15px;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  position: fixed;
  top: 15px;
  left: 60px;
  color: $green;
  transition: all 0.3s ease;
  background-color: $black-transparent;
  &.open {
    padding: 10px;
  }
  &:not(.open) {
    width: 30px;
    height: 30px;
    &:after {
      content: '?';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.text-red {
  color: $red !important;
}
.text-green {
  color: $green !important;
}
.text-orange {
  color: $orange !important;
}
.text-blue {
  color: $secondary-color !important;
}
.standard-btn {
  background-color: transparent;
  position: relative;
  border: 0;
  color: white;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  
  &.red-btn {
    &:hover,
    &.active {
      color: $red;
      .top {
        background-image: linear-gradient(to right, $red 50%, black 50%) !important;
      }
      .right {
        background-image: linear-gradient($red 50%, black 50%) !important;
      }
      .bottom {
        background-image: linear-gradient(to right, $red 50%, black 50%) !important;
      }
      .left {
        background-image: linear-gradient($red 50%, black 50%) !important;
      }
    }
  }
  &.green-btn {
    &:hover,
    &.active {
      color: $green;
      .top {
        background-image: linear-gradient(to right, $green 50%, black 50%) !important;
      }
      .right {
        background-image: linear-gradient($green 50%, black 50%) !important;
      }
      .bottom {
        background-image: linear-gradient(to right, $green 50%, black 50%) !important;
      }
      .left {
        background-image: linear-gradient($green 50%, black 50%) !important;
      }
    }
  }
  &.blue-btn {
    &:hover,
    &.active {
      color: $secondary-color;
      .top {
        background-image: linear-gradient(to right, $secondary-color 50%, black 50%) !important;
      }
      .right {
        background-image: linear-gradient($secondary-color 50%, black 50%) !important;
      }
      .bottom {
        background-image: linear-gradient(to right, $secondary-color 50%, black 50%) !important;
      }
      .left {
        background-image: linear-gradient($secondary-color 50%, black 50%) !important;
      }
    }
  }
  &.orange-btn {
    &:hover,
    &.active {
      color: $orange;
      .top {
        background-image: linear-gradient(to right, $orange 50%, black 50%) !important;
      }
      .right {
        background-image: linear-gradient($orange 50%, black 50%) !important;
      }
      .bottom {
        background-image: linear-gradient(to right, $orange 50%, black 50%) !important;
      }
      .left {
        background-image: linear-gradient($orange 50%, black 50%) !important;
      }
    }
  }
}
.btns-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  & > .standard-btn {
    margin: 5px;
  }
  small {
    font-size: 9px;
    vertical-align: super;
  }
}
#projects {
  .project-image {
    width: 100%;
  }
}
#login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    max-width: 320px;
    min-width: 320px;
    input, label {
      width: 100%;
    }
  }
}
#settings {
  .container  {
    display: flex;
    justify-content: space-around;
    > div {
      width: calc(50% - 25px);
    }
  }
  table {
    margin-top: 25px;
    width: 100%;
    thead th {
      border-top: 2px dotted $orange;
      border-bottom: 2px dotted $orange;
      border-right: 2px dotted $orange;
      padding: 2px 10px;
      text-align: left;
      color: $orange;
      &.big-col {
        min-width: 160px;
        width: 160px;
      }
      &:first-child {
        border-left: 2px dotted $orange;
      }
    }
    td {
      padding: 2px 10px;
      border-right: 2px dotted white;
      border-bottom: 2px dotted white;
      &:first-child {
        border-left: 2px dotted white;
        text-align: left;
      }
      .flex-box {
        justify-content: flex-start;
        > p {
          margin-right: 5px;
          margin-top: 0;
          margin-bottom: 0;
          &.edit {
            color: $blue;
            &:hover {
              opacity: 0.6;
            }
          }
          &.remove  {
            color: $red;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}
#videos {
  .flex-box {
    flex-wrap: wrap;
  }
}
.video {
  position: relative;
  margin: 10px;
  width: calc(100% / 3 - 30px);
  max-width: 320px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
}
.videos-box,
.photos-box {
  width: 100%;
  position: relative;
  padding: 15px;
  &:not(.photos-box) {
    height: calc(100vh - 300px);
  }
  .videos,
  .photos {
    width: 100%;
    position: relative;
    z-index: 1;
    &:not(.photos) {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 350px);
    }
    .video {
      width: calc(100% / 2 - 20px);
      max-width: calc(100% / 2 - 40px);
      height: 300px;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    .image {
      margin: 10px;
      position: relative;
      z-index: 1;
      padding: 15px;
      break-inside: avoid-column;
      cursor: pointer;
      // transition: all 0.3s ease;
      img {
        width: 100%;
        background-color: white;
      }
      * {
        pointer-events: none;
      }
    }
  }
  h2 {
    width: 100%;
  }
  h3 {
    // line-height: 1;
    font-size: 12px;
  }
  .video-details {
    height: 100px;
    width: 100px;
  }
}
.photos-box {
  overflow: auto;
  height: calc(100vh - 270px);
  .photos {
    columns: 300px 3;
  }
}
.video-details {
  position: relative;
  z-index: 1;
  padding: 15px;
  height: 200px;
  .title {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: $black-transparent-light;
    display: flex;
    align-items: center;
    h3 {
      background-color: $black-transparent-light;
      padding: 10px 0;
    }
  }
  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }
}
.show {
  position: fixed !important;
  z-index: 2 !important;
  background-color: black !important;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  // width: 90vw;
  margin: 0 !important;
  img {
    object-fit: contain;
    margin: 15px auto;
    height: calc(100% - 50px);
    display: block;
  }
  &.landscape {
    width: 80%;
  }
  &.portrait {
    height: 80%;
  }
}
#aboutme {
  img {
    width: 250px;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .flex-box {
    position: relative;
    width: 70%;
    margin: auto;
  }
  b {
    font-weight: 800;
    color: $green;
  }
  h2 {
    color: $secondary-color;
  }
  h3 {
    margin-top: 15px;
    color: $orange;
  }
  p {
    text-align: center;
  }
  ul {
    text-align: center;
    padding: 0;
    list-style: none;
    li {
      margin: 5px 0;
    }
  }
  .description {
    position: relative;
    z-index: 1;
    padding: 20px;
  }
}
#mini-editor {
  .flex-box {
    justify-content: start;
  }
  h2 {
    width: 100%;
  }
  .editor-box {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    & > div {
      height: 300px;
    }
    .html, .css {
      width: calc(50% - 20px);
      .box {
        display: flex;
        max-height: calc(100% - 70px);
        overflow-y: auto;
        align-items: flex-start;
        border: 1px solid white;
        padding-left: 15px;
      }
      ul {
        margin: 0;
        padding: 15px 0 0 0;
        font-size: 12px;
        list-style: none;
        margin-right: 10px;
        line-height: 25px;
      }
    }
  }
  .preview-box {
    width: 100%;
    max-height: 530px;
    margin-left: auto;
    .preview {
      border: 1px solid white;
      height: 100%;
      padding: 15px;
    }
  }
  textarea {
    width: 600px;
    background-color: $black;
    resize: none;
    color: white;
    outline: none;
    padding: 15px;
    border-color: white;
    border-width: 0 0 0 1px;
    overflow: hidden;
    font-size: 12px;
    min-height: 30px;
    line-height: 25px;
  }
  #preview {
    max-height: calc(100vh - 520px);
    overflow-y: auto;
  }
}
#view-toggle {
  position: fixed;
  z-index: 2;
  left: 15px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  .standard-btn {
    background-color: black;
  }
}
#egg-counter {
  position: fixed;
  z-index: 999;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 10px;
  p {
    padding: 0 5px;
    margin: 5px 0;
  }
  .eggs {
    display: flex;
  }
  .egg {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background-color: black;
  }
}
.checkmark {
  &.checked {
    color: $green;
  }
}
#streams {
  background-color: $purple;
  z-index: 1;
  position: relative;
  height: calc(100vh - 50px);
  overflow: auto;
  .coding,
  .gaming {
    width: 100%;
    margin: 10px;
    position: relative;
    padding: 15px;
    h3 {
      font-size: 1.3em;
      background-color: white;
      color: $purple;
      padding: 5px 15px;
      width: 300px;
      margin: 0 auto 20px auto,
    }
    .flex-box {
      justify-content: start;
    }
    .stats {
      width: 200px;
      position: relative;
    }
    .live {
      width: calc(100% - 215px);
      margin-left: auto;
    }
    .status {
      position: relative;
      padding-bottom: 15px;
      font-size: 1.2em;
      text-align: center;
    }
    .stream-logo {
      border-radius: 50%;
      margin: 0 auto 15px auto;
      display: block;
      width: 100px;
    }
    iframe {
      position: relative;
      z-index: 2;
      margin-top: 15px;
      width: 100%;
      min-height: 480px;
      // min-width: 720px;
    }
    h1 {
      margin: 0;
    }
  }
}
#congratulation {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 99;
  sup {
    font-size: 12px;
    vertical-align: top;
    &.final {
      position: fixed;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
#contact {
  a {
    text-decoration: none;
    cursor: pointer;
  }
}
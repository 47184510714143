$main-color: #5d46dd;
$secondary-color: #3a8fff;
$dark-color: #353535;
$light-color: rgb(231, 235, 238);
$black: black;
$black-transparent: rgba(0, 0, 0, 0.9);
$black-transparent-light: rgba(0, 0, 0, 0.6);
$white-transparent-light: rgba(255, 255, 255, 0.4);
$green: rgb(14, 255, 106);
$orange: rgb(255, 179, 0);
$red: #e82525;
$silver: silver;
$purple: rgb(150, 69, 226);

$background-color: $black;
$light-background: $light-color;
$btn-color: $main-color;
$btn-secondary-color: $secondary-color;
$blue: $secondary-color;
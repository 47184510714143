@media screen and (max-width: 812px) {
    body {
        .socials {
            transform: translateX(-50%);
            left: 50%;
            width: 180px;
            .btn {
                min-width: 30px !important;
            }
        }
        padding: 10px !important;
    }
    .App {
        #hello-page h1 {
            font-size: 22px;
        }
        .help.open {
            width: 75%;
            p, span, li {
                font-size: 12px;
            }
        }
        #navigation {
            width: calc(100vw - 50px);
        }
        #projects {
            .repo-box {
                width: 100% !important;
            }
        }
        #aboutme {
            .flex-box {
                width: 100%;
                p, b {
                    font-size: 12px;
                }
                ul {
                    margin: 5px 0;
                    li {
                        font-size: 12px;
                    }
                }
            }
        }
        #videos {
            .video {
                width: 100%;
            }
        }
        #mini-editor {
            overflow-y: auto;
            .html, .css {
                width: 100% !important;
                .box {
                    height: calc(100% - 40px);
                    max-height: initial !important;
                    textarea {
                        border: 0;
                    }
                }
            }
        }
        #egg-counter {
            bottom: auto;
            top: 2px;
            right: 0;
            flex-direction: row;
        }
        #archive {
            .image {
                &.portrait.show {
                    width: 80%;
                }
            }
        }
        #streams {
            .stats {
                display: none;
            }
            .live {
                width: 100% !important;
            }
        }
        #view-toggle {
            left: 0;
            bottom: 20px;
            width: calc(100% - 15px);
            .btns-nav {
                margin-bottom: 0;
                width: 100%;
                .standard-btn:last-child {
                    margin-left: auto;
                }
            }
        }
    }
}